<template>
  <div class="row">
    <div class="col-md-8">
      <b-form @submit.prevent="submitUpdate">
        <b-card class="mb-1" header="Credênciais">
          <b-card-text>
            <b-row>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <label for="">E-mail</label>
                  <input
                    type="email"
                    v-model="item.credentials.email"
                    class="form-control"
                  />
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <label for="">Token</label>
                <div class="form-group">
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': $v.item.credentials.key.$error }"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="password"
                      v-model="item.credentials.key"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      placeholder="Senha"
                      required
                      :class="{ 'is-invalid': $v.item.credentials.key.$error }"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </div>
                    </div>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-tabs pills content-class="mt-0" class="mb-0">
          <b-tab active>
            <template #title>
              <span class="font-weight-bold">Sistema</span>
            </template>

            <b-card class="mb-1" header="Usuário">
              <b-card-text>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Cadastro" label-cols-md="4">
                      <b-form-input v-model="item.templates.user_new" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <span class="font-weight-bold">Aluno</span>
            </template>
            <b-card class="mb-1" header="Aluno">
              <b-card-text>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Cadastro" label-cols-md="4">
                      <b-form-input v-model="item.templates.customer_new" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Senha Atualizada" label-cols-md="4">
                      <b-form-input
                        v-model="item.templates.customer_update_password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Recuperação de Senha"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.customer_forget_password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Novo Pedido" label-cols-md="4">
                      <b-form-input
                        v-model="item.templates.customer_payment_online_new"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Pagamento Rejeitado" label-cols-md="4">
                      <b-form-input
                        v-model="
                          item.templates.customer_payment_online_rejected
                        "
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Pagamento Aprovado" label-cols-md="4">
                      <b-form-input
                        v-model="item.templates.customer_payment_online_paid"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Desconto de Aniversário"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.customer_discount_birthday"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <span class="">Checkout</span>
            </template>

            <b-card class="mb-1" header="Checkout">
              <b-card-text>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Checkout - Pré-matrícula"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.crm_checkout_enrollment"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Solicitação de matricula 1 dia depois!"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.enrollment_request_day1"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Solicitação de matricula 2 dia depois!"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.enrollment_request_day2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Solicitação de matricula 3 dia depois!"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.enrollment_request_day3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Confirmação de pagamento de link de matrícula"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="item.templates.enrollment_payment_confirmation"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="font-weight-bold">CRM</span>
            </template>

            <div
              class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
            >
              <div class="card-header mb-2">
                <div class="form-row">
                  <div class="col-md-10 col-8">
                    <h4 class="mb-0">
                      Templates
                      <small class="d-block" style="margin-top: 5px">
                        Cadastre os templates a serem exibidos na plataforma
                        Seducar CRM.
                      </small>
                    </h4>
                  </div>
                  <div class="col-md-2 col-4">
                    <button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeateAgain"
                      class="btn btn-primary btn-block"
                      type="button"
                    >
                      <i class="bi bi-plus mr-25"></i>
                      <span>Novo</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <ul class="mt-1" style="list-style: none; padding: 0px">
                  <li v-for="(crm, index) in item.crm" :key="index">
                    <div class="form-row mb-1">
                      <div class="col-md-4">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Nome do template"
                          v-model="crm.name"
                          :class="{
                            'is-invalid': $v.item.crm.$each[index].name.$error,
                          }"
                        />
                      </div>

                      <div class="col-md-7">
                        <input
                          class="form-control"
                          placeholder="Código do template no SendGrid"
                          type="text"
                          v-model="crm.code"
                          :class="{
                            'is-invalid': $v.item.crm.$each[index].code.$error,
                          }"
                        />
                      </div>

                      <div class="col-md-1">
                        <button
                          style="
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                            text-align: center;
                          "
                          type="button"
                          class="btn px-0 btn-danger btn-block"
                          @click="confirmDelete(crm.uuid, index)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            fill="none"
                          >
                            <path
                              d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.5 16.5L9.5 10.5"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                            <path
                              d="M14.5 16.5L14.5 10.5"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              text="Salvar"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </b-form>
    </div>
    <div class="col-md-4">
      <p>
        A Seducar utiliza a SendGrid para envio de e-mails transacionais. Para
        ativar o envio de e-mail, é preciso cadastrar o token e os templates.
      </p>
      <b-alert variant="dark" show d-block class="mb-1">
        <h4 class="alert-heading">Como encontrar o ID do template?</h4>
        <div class="alert-body">
          Após fazer login no dashboard da SendGrid, acesse o menu Email API >
          Dynamic Templates.
          <p>Feito isso, clique no template criado e copie o Template ID.</p>
        </div>
      </b-alert>
      <b-alert class="mb-1" variant="dark" show d-block>
        <h4 class="alert-heading">Para que serve os templates?</h4>
        <div class="alert-body">
          Os templates servem para que você possa ter a liberdade de criar e/ou
          alterar o conteúdo de cada e-mail. <br />
          Também é possível criar um template no SendGrid, com dados dinâmicos.
          Com isso, você poderá exibir os dados do cliente, por exemplo.
        </div>
      </b-alert>
      <h4 class="my-2">Estrutura de dados</h4>
      <p>
        É aconselhável a utilização dos códigos para exibição de informações do
        aluno, escola e produtos. Depende do objetivo do e-mail.
      </p>
      <app-collapse>
        <app-collapse-item
          class="user-email-template"
          title="Usuário - Cadastro"
        >
          <b-card>
            <div class="language-markup">
              <strong>user.name:</strong> Exibe nome do Aluno <br />
              <strong>user.email:</strong> Exibe o e-mail do Aluno<br />
              <strong>user.cpf:</strong> Exibe CPF do Aluno<br />
              <strong>user.password:</strong> Exibe Password do Aluno<br />
              <hr />
              <strong>school.name:</strong> Exibe nome do Aluno <br />
              <strong>school.logo:</strong> Logo da escola<br />
              <strong>school.link:</strong> Link do dashboard<br />
              <hr />
              <strong>social.instagram:</strong> Instagram da escola <br />
              <strong>social.facebook:</strong> Facebook da escola<br />
              <strong>social.linkedin:</strong> LinkedIn da escola<br />
              <strong>social.tiktok:</strong> Tiktok da escola<br />
              <strong>social.youtube:</strong> Youtube da escola<br />
            </div>
          </b-card>
        </app-collapse-item>
      </app-collapse>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BAlert,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BCardText,
  BInputGroup,
  VBTooltip,
} from "bootstrap-vue";
import { mapState } from "vuex";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import Ripple from "vue-ripple-directive";
import { required, minLength, email } from "vuelidate/lib/validators";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BAlert,
    BInputGroup,
    BCard,
    BForm,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    ButtonsActionsFooter,
  },
  data() {
    return {
      submited: false,
      nextTodoId: 1,
      item: {
        credentials: {
          name: "",
          key: "",
        },
        templates: {},
        crm: [],
      },
    };
  },
  computed: {
    ...mapState("Config", ["email"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    this.getData();
  },
  mixins: [togglePasswordVisibility],
  validations: {
    item: {
      credentials: {
        name: {
          required,
          minLength: minLength(3),
        },
        email: {
          required,
          email,
        },
        key: {
          required,
          minLength: minLength(5),
        },
      },
      templates: {},
      crm: {
        $each: {
          name: { required },
          code: { required },
        },
      },
    },
  },
  methods: {
    repeateAgain() {
      this.item.crm.push({
        name: "",
        code: "",
      });
    },
    confirmDelete(uuid, index) {
      this.$swal({
        title: "Tem certeza disso?",
        text: "Ao excluir, as oportunidades associadas ficarão sem etapa definida. Deseja continuar?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$store
            .dispatch("Config/deleteSchoolEmailSendgridTemplate", uuid)
            .then(() => {
              this.item.crm.splice(index, 1);
            });
        }
      });
    },
    submitUpdate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        delete this.item.credentials.id;
        delete this.item.templates.id;
        delete this.item.templates.uuid;

        this.$store
          .dispatch("Config/emailUpdate", this.item)
          .then(() => {
            this.notify(
              "Operação realizada com sucesso!",
              "UserCheckIcon",
              "success",
              ""
            );
          })
          .catch((err) => {
            this.submited = false;
            console.log(err);
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.$store.dispatch("Config/email").then(() => {
        if (this.email) {
          this.item = this.email;
        }
      });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
};
</script>

<style lang="scss">
.user-email-template {
  .collapse {
    > .card-body {
      padding: 0px;
    }
  }
}
</style>
