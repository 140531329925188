var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c(
          "b-form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitUpdate.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "b-card",
              { staticClass: "mb-1", attrs: { header: "Credênciais" } },
              [
                _c(
                  "b-card-text",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("E-mail"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.credentials.email,
                                  expression: "item.credentials.email",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "email" },
                              domProps: { value: _vm.item.credentials.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item.credentials,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Token"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "input-group-merge",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.credentials.key.$error,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "form-control-merge",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.credentials.key.$error,
                                    },
                                    attrs: {
                                      autocomplete: "off",
                                      id: "password",
                                      type: _vm.passwordFieldType,
                                      placeholder: "Senha",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.item.credentials.key,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.item.credentials,
                                          "key",
                                          $$v
                                        )
                                      },
                                      expression: "item.credentials.key",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              icon: _vm.passwordToggleIcon,
                                            },
                                            on: {
                                              click:
                                                _vm.togglePasswordVisibility,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tabs",
              {
                staticClass: "mb-0",
                attrs: { pills: "", "content-class": "mt-0" },
              },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { active: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Sistema"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1", attrs: { header: "Usuário" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Cadastro",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.item.templates.user_new,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "user_new",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.user_new",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Aluno"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1", attrs: { header: "Aluno" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Cadastro",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates.customer_new,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_new",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_new",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Senha Atualizada",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_update_password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_update_password",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_update_password",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Recuperação de Senha",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_forget_password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_forget_password",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_forget_password",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Novo Pedido",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_payment_online_new,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_payment_online_new",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_payment_online_new",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Pagamento Rejeitado",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_payment_online_rejected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_payment_online_rejected",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        item.templates.customer_payment_online_rejected\n                      ",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Pagamento Aprovado",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_payment_online_paid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_payment_online_paid",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_payment_online_paid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Desconto de Aniversário",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .customer_discount_birthday,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "customer_discount_birthday",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.customer_discount_birthday",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [_c("span", {}, [_vm._v("Checkout")])]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1", attrs: { header: "Checkout" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Checkout - Pré-matrícula",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .crm_checkout_enrollment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "crm_checkout_enrollment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.crm_checkout_enrollment",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Solicitação de matricula 1 dia depois!",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .enrollment_request_day1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "enrollment_request_day1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.enrollment_request_day1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Solicitação de matricula 2 dia depois!",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .enrollment_request_day2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "enrollment_request_day2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.enrollment_request_day2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Solicitação de matricula 3 dia depois!",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .enrollment_request_day3,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "enrollment_request_day3",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.enrollment_request_day3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Confirmação de pagamento de link de matrícula",
                                          "label-cols-md": "4",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.item.templates
                                                .enrollment_payment_confirmation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item.templates,
                                                "enrollment_payment_confirmation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.templates.enrollment_payment_confirmation",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("CRM"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                      },
                      [
                        _c("div", { staticClass: "card-header mb-2" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-10 col-8" }, [
                              _c("h4", { staticClass: "mb-0" }, [
                                _vm._v(" Templates "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "margin-top": "5px" },
                                  },
                                  [
                                    _vm._v(
                                      " Cadastre os templates a serem exibidos na plataforma Seducar CRM. "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-2 col-4" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { 400: true },
                                    },
                                  ],
                                  staticClass: "btn btn-primary btn-block",
                                  attrs: { variant: "primary", type: "button" },
                                  on: { click: _vm.repeateAgain },
                                },
                                [
                                  _c("i", { staticClass: "bi bi-plus mr-25" }),
                                  _c("span", [_vm._v("Novo")]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "mt-1",
                              staticStyle: {
                                "list-style": "none",
                                padding: "0px",
                              },
                            },
                            _vm._l(_vm.item.crm, function (crm, index) {
                              return _c("li", { key: index }, [
                                _c("div", { staticClass: "form-row mb-1" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: crm.name,
                                          expression: "crm.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.crm.$each[index].name
                                            .$error,
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "Nome do template",
                                      },
                                      domProps: { value: crm.name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            crm,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: crm.code,
                                          expression: "crm.code",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.crm.$each[index].code
                                            .$error,
                                      },
                                      attrs: {
                                        placeholder:
                                          "Código do template no SendGrid",
                                        type: "text",
                                      },
                                      domProps: { value: crm.code },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            crm,
                                            "code",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col-md-1" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn px-0 btn-danger btn-block",
                                        staticStyle: {
                                          "padding-left": "0px !important",
                                          "padding-right": "0px !important",
                                          "text-align": "center",
                                        },
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmDelete(
                                              crm.uuid,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 24 24",
                                              width: "16",
                                              height: "16",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M9.5 16.5L9.5 10.5",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M14.5 16.5L14.5 10.5",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "form-row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ButtonsActionsFooter", {
                    attrs: {
                      variant: "success",
                      submited: _vm.submited,
                      text: "Salvar",
                      subtext: "Aguarde...",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c("p", [
          _vm._v(
            " A Seducar utiliza a SendGrid para envio de e-mails transacionais. Para ativar o envio de e-mail, é preciso cadastrar o token e os templates. "
          ),
        ]),
        _c(
          "b-alert",
          {
            staticClass: "mb-1",
            attrs: { variant: "dark", show: "", "d-block": "" },
          },
          [
            _c("h4", { staticClass: "alert-heading" }, [
              _vm._v("Como encontrar o ID do template?"),
            ]),
            _c("div", { staticClass: "alert-body" }, [
              _vm._v(
                " Após fazer login no dashboard da SendGrid, acesse o menu Email API > Dynamic Templates. "
              ),
              _c("p", [
                _vm._v(
                  "Feito isso, clique no template criado e copie o Template ID."
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "b-alert",
          {
            staticClass: "mb-1",
            attrs: { variant: "dark", show: "", "d-block": "" },
          },
          [
            _c("h4", { staticClass: "alert-heading" }, [
              _vm._v("Para que serve os templates?"),
            ]),
            _c("div", { staticClass: "alert-body" }, [
              _vm._v(
                " Os templates servem para que você possa ter a liberdade de criar e/ou alterar o conteúdo de cada e-mail. "
              ),
              _c("br"),
              _vm._v(
                " Também é possível criar um template no SendGrid, com dados dinâmicos. Com isso, você poderá exibir os dados do cliente, por exemplo. "
              ),
            ]),
          ]
        ),
        _c("h4", { staticClass: "my-2" }, [_vm._v("Estrutura de dados")]),
        _c("p", [
          _vm._v(
            " É aconselhável a utilização dos códigos para exibição de informações do aluno, escola e produtos. Depende do objetivo do e-mail. "
          ),
        ]),
        _c(
          "app-collapse",
          [
            _c(
              "app-collapse-item",
              {
                staticClass: "user-email-template",
                attrs: { title: "Usuário - Cadastro" },
              },
              [
                _c("b-card", [
                  _c("div", { staticClass: "language-markup" }, [
                    _c("strong", [_vm._v("user.name:")]),
                    _vm._v(" Exibe nome do Aluno "),
                    _c("br"),
                    _c("strong", [_vm._v("user.email:")]),
                    _vm._v(" Exibe o e-mail do Aluno"),
                    _c("br"),
                    _c("strong", [_vm._v("user.cpf:")]),
                    _vm._v(" Exibe CPF do Aluno"),
                    _c("br"),
                    _c("strong", [_vm._v("user.password:")]),
                    _vm._v(" Exibe Password do Aluno"),
                    _c("br"),
                    _c("hr"),
                    _c("strong", [_vm._v("school.name:")]),
                    _vm._v(" Exibe nome do Aluno "),
                    _c("br"),
                    _c("strong", [_vm._v("school.logo:")]),
                    _vm._v(" Logo da escola"),
                    _c("br"),
                    _c("strong", [_vm._v("school.link:")]),
                    _vm._v(" Link do dashboard"),
                    _c("br"),
                    _c("hr"),
                    _c("strong", [_vm._v("social.instagram:")]),
                    _vm._v(" Instagram da escola "),
                    _c("br"),
                    _c("strong", [_vm._v("social.facebook:")]),
                    _vm._v(" Facebook da escola"),
                    _c("br"),
                    _c("strong", [_vm._v("social.linkedin:")]),
                    _vm._v(" LinkedIn da escola"),
                    _c("br"),
                    _c("strong", [_vm._v("social.tiktok:")]),
                    _vm._v(" Tiktok da escola"),
                    _c("br"),
                    _c("strong", [_vm._v("social.youtube:")]),
                    _vm._v(" Youtube da escola"),
                    _c("br"),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }